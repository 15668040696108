import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function Tradeshows() {
	const pageId = 16

	return (
		<div className="SOUKIASSIAN-Tradeshows">
			<Header
				type="finalPage"
				title="SALON"
				subTitle="Animé par Christophe Soukiassian"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								<b>Organisateur</b> : Zen et Bio
							</span>
							<span>
								<b>Ville</b> : 69000 Lyon
								<br />
								<b>Nom du lieu</b> : Eurexpo
								{/* <br />
								<br />
								<b>Adresse</b> : En prévision */}
							</span>
							<span>
								<b>Date</b> : 15 au 17 novembre 2024
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					text={
						<>
							<span>
								<b>Organisateur</b> : La médecine
							</span>
							<span>
								<b>Ville</b> : 69000 Lyon
								<br />
								<b>Nom du lieu</b> : Cité internationale
							</span>
							<span>
								<b>Date</b> : 17 au 19 janvier 2025
							</span>
						</>
					}
					left
				/>
				<ImageText pageId={pageId} entityId={5} />
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Gabriel Fontaine"
				commentary={
					<>
						Ce salon est accueillant. Au stand de l'école LCC, j'ai
						été encouragé et inspiré.
						<br />
						Je ressens que mes rêves peuvent prendre vie avec le
						soutien et les accompagnements de Christophe
						Soukiassian.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>SALON</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Tradeshows
