import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../../actions/page.action'
import { getImages } from '../../../actions/image.action'

import {
	getDataFromImageList,
	filterImageListToArray,
	getAllImageIdFromPageList,
	getAllFolderFromPageList,
	folderExist,
	getAllImageIdFromFolder,
	isRightAccess,
} from '../../../utils'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'

import ImageRow from './ImageRow'
import AddImageRow from './AddImageRow'
import ImageHandling from './ImageHandling'

import './index.scss'
import { faCircleLeft } from '@fortawesome/free-regular-svg-icons'

function AdminImageTable({ onClickImgPersonalized, forChangeImage }) {
	const [imageUpdate, setImageUpdate] = useState(false)
	const [folderImagesId, setFolderImagesId] = useState('ALL')
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const user = useSelector((state) => state.userReducer)
	const dispatch = useDispatch()

	useEffect(() => {
		if (isRightAccess(user, 4)) {
			for (let i = 1; i <= 27; i++) {
				dispatch(getPage(i))
			}
			dispatch(getImages())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onClickImg = onClickImgPersonalized
		? onClickImgPersonalized
		: (e, imgId) => {
				setImageUpdate(getDataFromImageList(imgId, imageList))
			}

	const onExit = () => {
		setImageUpdate(false)
	}

	const openFolder = (folderId) => {
		setFolderImagesId(folderId)
	}

	return (
		<>
			{isRightAccess(user, 4) && (
				<div className="SOUKIASSIAN-AdminImageTable">
					{imageUpdate ? (
						<ImageHandling
							onExit={onExit}
							key={imageUpdate.id}
							imgId={imageUpdate.id}
							url={imageUpdate.url}
							role={imageUpdate.role}
							author={imageUpdate.author}
							site={imageUpdate.site}
							author_link={imageUpdate.author_link}
							source_link={imageUpdate.source_link}
							extension={imageUpdate.extension}
							direction={imageUpdate.direction}
							size={imageUpdate.size}
							weight={imageUpdate.weight}
							change_made={imageUpdate.change_made}
						/>
					) : (
						<>
							{!forChangeImage && isRightAccess(user, 5) && (
								<AddImageRow />
							)}

							{folderImagesId === 'ALL' && (
								<div className="SOUKIASSIAN-AdminImageTable-folder-container">
									<div
										key={0}
										className="SOUKIASSIAN-AdminImageTable-folder"
									>
										<FontAwesomeIcon
											icon={faFolder}
											className="SOUKIASSIAN-AdminImageTable-icon"
											onClick={() => openFolder('NoUsed')}
										/>
										0 : Non utilisées
									</div>
									{getAllFolderFromPageList(pageList).map(
										(folder) => (
											<div
												key={folder.id}
												className="SOUKIASSIAN-AdminImageTable-folder"
											>
												<FontAwesomeIcon
													icon={faFolder}
													className="SOUKIASSIAN-AdminImageTable-icon"
													onClick={() =>
														openFolder(folder.id)
													}
												/>
												{`${folder.id} : ${folder.name}`}
											</div>
										),
									)}
								</div>
							)}
							{folderImagesId === 'NoUsed' && (
								<div className="SOUKIASSIAN-AdminImageTable-ImageRow-container">
									{imageList &&
										filterImageListToArray(
											imageList,
											(image) => {
												return !getAllImageIdFromPageList(
													pageList,
												).includes(image.id)
											},
										).map((image) => (
											<ImageRow
												onClickImg={onClickImg}
												key={image.id}
												imgId={image.id}
												url={image.url}
												role={image.role}
												noDelete={forChangeImage}
											/>
										))}
									<FontAwesomeIcon
										icon={faCircleLeft}
										className="SOUKIASSIAN-AdminImageTable-icon"
										onClick={() => openFolder('ALL')}
									/>
								</div>
							)}
							{folderExist(pageList, folderImagesId) && (
								<div className="SOUKIASSIAN-AdminImageTable-ImageRow-container">
									{imageList &&
										filterImageListToArray(
											imageList,
											(image) => {
												return getAllImageIdFromFolder(
													pageList[folderImagesId],
												).includes(image.id)
											},
										).map((image) => (
											<ImageRow
												onClickImg={onClickImg}
												key={image.id}
												imgId={image.id}
												url={image.url}
												role={image.role}
												noDelete={forChangeImage}
											/>
										))}
									<FontAwesomeIcon
										icon={faCircleLeft}
										className="SOUKIASSIAN-AdminImageTable-icon"
										onClick={() => openFolder('ALL')}
									/>
								</div>
							)}
						</>
					)}
				</div>
			)}
		</>
	)
}

AdminImageTable.propTypes = {
	onClickImgPersonalized: PropTypes.func,
	notAddImage: PropTypes.bool,
}

AdminImageTable.defaultProps = {
	forChangeImage: false,
}

export default AdminImageTable
