import { useSelector } from 'react-redux'

import HarmoniousTitle from '../../components/HarmoniousTitle'
import PictureTextLink from '../../components/PictureTextLink'

import { IS_DEV_ENVIRONMENT } from '../../utils/env'

import './index.scss'

function RegistrationCategoryList() {
	const isConnectedUser = useSelector(
		(state) => state.userReducer.isConnected,
	)
	const homePageId = 2
	const eventPageId = 3
	const stagePageId = 4

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À L'ÉVÉNEMENT"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="event/webinars"
						pageId={eventPageId}
						entityId={2}
						title="WEBINAIRE"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT && !isConnectedUser) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE AUX STAGES"
				sticky
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/eat-well"
						pageId={stagePageId}
						entityId={5}
						title="Bien se Nourrir"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/foreign-trip"
						pageId={stagePageId}
						entityId={6}
						title="Séjour à l'Étranger"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/sagacity"
						pageId={stagePageId}
						entityId={3}
						title="Sagesse"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/meditation"
						pageId={stagePageId}
						entityId={4}
						title="Méditation"
						flipped
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
					<PictureTextLink
						className="col-xxs-12 col-xs-12 col-5"
						link="stage/consciousness-initiation"
						pageId={stagePageId}
						entityId={2}
						title="Initiation Conscience"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À LA FORMATION<br>COMMUNICATION CONSCIENTE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="trainings/training-life-coaching"
						pageId={homePageId}
						entityId={5}
						title="COACHER SA VIE"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
				</div>
			</HarmoniousTitle>

			<HarmoniousTitle
				className="col-xl-max-blocked"
				title="S'INSCRIRE À LA FORMATION<br>COACH PLEINE CONSCIENCE L.C.C. ©"
			>
				<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
					<PictureTextLink
						link="trainings/training-become-coach"
						pageId={homePageId}
						entityId={6}
						title="DEVENIR COACH"
						onClick={(e) => {
							if (!IS_DEV_ENVIRONMENT) {
								e.preventDefault()
								alert(
									"Page en cours d'actualisation juillet-août 2024",
								)
							}
						}}
					/>
				</div>
			</HarmoniousTitle>
		</div>
	)
}

export default RegistrationCategoryList
