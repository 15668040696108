import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function Conferences() {
	const pageId = 17

	return (
		<div className="SOUKIASSIAN-Conferences">
			<Header
				type="finalPage"
				title="CONFÉRENCE"
				subTitle={
					<>
						<br />
						Animée par Christophe Soukiassian
						<br />
						<br />
						“Trouvez votre potentiel véritable. Osez une vie
						authentique. Transcendez vos difficultés. Brillez en
						conscience.”
					</>
				}
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								<b>Organisateur</b> : Christophe Soukiassian
							</span>
							<span>
								<b>Ville</b> : 71220 Vérosvres
								<br />
								<b>Nom du lieu</b> : Vos rêves
								<br />
								<b>Adresse</b> : 109 impasse du verger
							</span>
							<span>
								<b>Date</b> : Le samedi 27 juillet 2024
								<br />
								<b>Horaire</b> : 15h - 16h
								<br />
								<b>Nom de la salle</b> : Le dôme
							</span>
							<span>
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
				/>
				<ImageText pageId={pageId} entityId={4} left />
				<ImageText pageId={pageId} entityId={5} />
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Marie Rivière"
				commentary={
					<>
						C'est si enrichissant et revivifiant cette conférence,
						qu'après j'ai eu plaisir de parler de mes projets avec
						Christophe Soukiassian.
						<br />
						<br />
						Son humanisme est très appréciable.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>CONFÉRENCE</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Conferences
