import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

import './index.scss'

function ForeignTrip() {
	const pageId = 23

	return (
		<div className="SOUKIASSIAN-ForeignTrip">
			<Header
				type="finalPage"
				title="STAGE VOYAGE À L'ÉTRANGER"
				subTitle="“Voyager avec la conscience communicative, vivez une élévation de conscience en groupe.”"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					title="VOYAGE INTÉRIEUR"
					text={
						<>
							<span>Partez en voyage à l'aventure !</span>
							<span>
								Retrouvons-nous pour un développement personnel
								et spirituel, captivant et rayonnant.
							</span>
							<span>
								Réunissons-nous, allons au fond de qui nous
								sommes vraiment, explorons le cœur de notre
								propre humanité.
							</span>
							<span>
								<br />
								<b>Nous rejoindre</b>
								<br />
								<br />
								pour atteindre le meilleur de vous-mêmes, et
								cultivez une évolution multidimensionnelle.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title={
						<>
							(7 jours)
							<br />
							Accompagné par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								<b>Partez à l'aventure</b>
								<br />
								“Explorez de nouveaux horizons.
								<br />
								Osez, vous rencontrer véritablement.”
							</span>
							<span>
								<b>Le voyage intérieur</b>
								<br />
								Les participants bravent les frontières de leur
								âme.
								<br />
								Ils voyagent au cœur de leur être.
							</span>
							<span>
								<b>Soyez en osmose</b>
								<br />
								Découvrez les merveilles existentielles cachées
								au plus profond de vous-mêmes.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Voyage à l'étranger”
						</>
					}
					textCenter
					text={
						<span>
							<Button link="/contact">S'INSCRIRE</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Mohammed Doucemar"
				commentary={
					<>
						Ce voyage à l'étranger m'a permis de me reconnecter
						profondément avec moi-même, loin des distractions et
						perturbation de la vie quotidienne.
						<br />
						J'ai découvert et vécu une semaine transcendante qui m'a
						ramené à l'essence de qui je suis vraiment.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={5}></BannerImageText>

			<Footer />
		</div>
	)
}

export default ForeignTrip
