import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainingBecomeCoach() {
	const pageId = 10

	return (
		<div className="SOUKIASSIAN-TrainingBecomeCoach">
			<Header
				type="finalPage"
				title="Formation en coaching pleine conscience"
				subTitle="DEVENIR COACH"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation
								innovante dédiée à la{' '}
								<b>
									pleine conscience dans des accompagnements
									en coaching.
								</b>
							</span>
							<span>
								Vous apprenez à{' '}
								<span className="underline">
									accompagner le développement des potentiels
								</span>{' '}
								et{' '}
								<span className="underline">
									l'accomplissement des projets de vie.
								</span>
							</span>
							<span>
								Ouverte à tous, la formation de coach vous donne{' '}
								<span className="underline">
									les solutions pour coacher brillamment les
									autres.
								</span>
							</span>
							<span>
								<span className="underline">
									Transformer les savoirs
								</span>{' '}
								de vos expériences de vie en un puissant outil
								de communication.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation est conçue par une expertise de
								plus de 20 ans avec des personnes de divers
								continents.
							</span>
							<span>
								Cette approche développe la communication
								consciente comme compétence d'accompagnement en
								coaching.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les modules de notre formation s'articulent
								autour d'une pédagogie fluide car intuitive.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage, unique,
								riche et complète.
							</span>
							<span className="underline">
								Les participants développent leur potentiel
								humain dans leurs accompagnements en
								communication.
							</span>
							<span>
								<span className="underline">
									Bénéficiez de la technologie la plus avancée
									en coaching
								</span>{' '}
								personnel et professionnel.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					flippedImage
					text={
						<>
							<span>
								<b>En groupe</b>
								<br />
								12 jours non-consécutifs.
								<br />
								185 € la journée par personne
							</span>
							<span>
								<b>En individuel</b>
								<br />
								12 demi-journées non-consécutives.
								<br />
								235 € la demi-journée par personne
							</span>
							<span>
								<hr />
								<Button
									onClick={(e) => {
										e.preventDefault()
										alert(
											"Le programme est en cours d'actualisation juillet-août 2024",
										)
									}}
								>
									Télécharger le programme
								</Button>
								<br />
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Christine"
				commentary={
					<>
						Je suis coach maintenant et toutes mes méthodes
						précédentes, je les applique dans mes coaching ou plutôt
						je dirais que j'applique le coaching de partout avec mes
						méthodes et en plus je suis moi-même dans ma vie, ce qui
						me soutient tous les jours dans mes réussites
						quotidiennes.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainingBecomeCoach
