import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { categoryListFormatting } from '../../../utils'
import { IS_DEV_ENVIRONMENT } from '../../../utils/env'

import { getRegistrationsData } from '../../../actions/registration.action'

import HarmoniousTitle from '../../../components/HarmoniousTitle'
import PictureTextLink from '../../../components/PictureTextLink'

import '../../RegistrationList/index.scss'

function RegistrationCategoryList() {
	const [categoryListFormatted, setCategoryListFormatted] = useState({})
	const isConnectedUser = useSelector(
		(state) => state.userReducer.isConnected,
	)
	const categoryList = useSelector(
		(state) => state.registrationReducer.categoryList,
	)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getRegistrationsData())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setCategoryListFormatted(categoryListFormatting(categoryList))
	}, [categoryList])

	return (
		<div className="SOUKIASSIAN-RegistrationList-Category">
			{categoryListFormatted.event && (
				<HarmoniousTitle
					className="col-max-blocked"
					title={categoryListFormatted.event.title_category}
					sticky
				>
					<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
						{categoryListFormatted.event.list.map((event) => {
							return (
								<PictureTextLink
									className="col-xxs-12 col-xs-12 col-5"
									key={event.id}
									link={event.link}
									pageId={event.id_page}
									entityId={event.id_image}
									title={event.title_subcategory}
									onClick={(e) => {
										if (
											!IS_DEV_ENVIRONMENT &&
											!isConnectedUser
										) {
											e.preventDefault()
											alert(
												"Page en cours d'actualisation juillet-août 2024",
											)
										}
									}}
								/>
							)
						})}
					</div>
				</HarmoniousTitle>
			)}

			{categoryListFormatted.stage && (
				<HarmoniousTitle
					className="col-xl-max-blocked"
					title={categoryListFormatted.stage.title_category[0]}
					sticky
				>
					<div className="SOUKIASSIAN-RegistrationList-Category-pictureList SOUKIASSIAN-RegistrationList-Category-pictureList-reverse">
						{categoryListFormatted.stage.list.map((stage) => {
							return (
								<PictureTextLink
									className="col-xxs-12 col-xs-12 col-5"
									key={stage.id}
									link={stage.link}
									pageId={stage.id_page}
									entityId={stage.id_image}
									title={stage.title_subcategory}
									onClick={(e) => {
										if (
											!IS_DEV_ENVIRONMENT &&
											!isConnectedUser
										) {
											e.preventDefault()
											alert(
												"Page en cours d'actualisation juillet-août 2024",
											)
										}
									}}
								/>
							)
						})}
					</div>
				</HarmoniousTitle>
			)}

			{categoryListFormatted.training && (
				<>
					<HarmoniousTitle
						className="col-max-blocked"
						title={categoryListFormatted.training.title_category[0]}
					>
						<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
							{categoryListFormatted.training.list.map(
								(training) => {
									if (
										training.subcategory ===
										'training-life-coaching'
									) {
										return (
											<PictureTextLink
												className="col-xxs-12 col-xs-12 col-5"
												key={training.id}
												link={training.link}
												pageId={training.id_page}
												entityId={training.id_image}
												title={
													training.title_subcategory
												}
												onClick={(e) => {
													if (
														!IS_DEV_ENVIRONMENT &&
														!isConnectedUser
													) {
														e.preventDefault()
														alert(
															"Page en cours d'actualisation juillet-août 2024",
														)
													}
												}}
											/>
										)
									}
									return false
								},
							)}
						</div>
					</HarmoniousTitle>

					<HarmoniousTitle
						className="col-max-blocked"
						title={categoryListFormatted.training.title_category[1]}
					>
						<div className="SOUKIASSIAN-RegistrationList-Category-pictureList">
							{categoryListFormatted.training.list.map(
								(training) => {
									if (
										training.subcategory ===
										'training-become-coach'
									) {
										return (
											<PictureTextLink
												className="col-xxs-12 col-xs-12 col-5"
												key={training.id}
												link={training.link}
												pageId={training.id_page}
												entityId={training.id_image}
												title={
													training.title_subcategory
												}
												onClick={(e) => {
													if (
														!IS_DEV_ENVIRONMENT &&
														!isConnectedUser
													) {
														e.preventDefault()
														alert(
															"Page en cours d'actualisation juillet-août 2024",
														)
													}
												}}
											/>
										)
									}
									return false
								},
							)}
						</div>
					</HarmoniousTitle>
				</>
			)}
		</div>
	)
}

export default RegistrationCategoryList
