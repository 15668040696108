import { useSelector } from 'react-redux'

import { isRightAccess } from '../../../utils'

import Login from '../../../components/Login'
import Header from '../../../components/Header'
import AdminImageTable from '../../../components/admin/ImageTable'

import Error403 from '../../Error/403'

function AdminImageHandler() {
	const user = useSelector((state) => state.userReducer)

	return (
		<>
			{user.isConnected ? (
				<>
					{isRightAccess(user, 4) ? (
						<div className="SOUKIASSIAN-AdminImageHandler">
							<Header type="admin" />
							<AdminImageTable />
						</div>
					) : (
						<div className="SOUKIASSIAN-AdminImageHandler">
							<Header type="admin" />
							<Error403 redirectLink="/admin/home" />
						</div>
					)}
				</>
			) : (
				<Login />
			)}
		</>
	)
}

export default AdminImageHandler
