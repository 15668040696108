import { initPage } from '../../utils'

const registrationListLoader = ({ params }) => {
	const id = params.id || 0

	const categoryToTitle = {
		event: {
			webinars: {
				title: 'événement webinaire',
				HarmoniousTitle: 'À UN ÉVÉNEMENT WEBINAIRE',
				pageId: 15,
				id: id,
			},
		},
		stage: {
			'consciousness-initiation': {
				title: 'stage initation conscience',
				HarmoniousTitle: 'AU STAGE INITIATION CONSCIENCE',
				pageId: 19,
				id: id,
			},
			sagacity: {
				title: 'stage sagesse',
				HarmoniousTitle: 'AU STAGE SAGESSE',
				pageId: 20,
				id: id,
			},
			meditation: {
				title: 'stage méditation',
				HarmoniousTitle: 'AU STAGE MÉDITATION',
				pageId: 21,
				id: id,
			},
			'eat-well': {
				title: 'stage bien se nourrir',
				HarmoniousTitle: 'AU STAGE BIEN SE NOURRIR',
				pageId: 22,
				id: id,
			},
			'foreign-trip': {
				title: "stage séjour à l'étranger",
				HarmoniousTitle: "AU STAGE SÉJOUR À L'ÉTRANGER",
				pageId: 23,
				id: id,
			},
		},
		training: {
			'training-life-coaching': {
				title: 'formation coacher sa vie',
				HarmoniousTitle: 'À LA FORMATION<br>COMMUNICATION CONSCIENTE',
				pageId: 9,
				id: id,
			},
			'training-become-coach': {
				title: 'formation devenir coach',
				HarmoniousTitle: 'À LA FORMATION<br>COACH PLEINE CONSCIENCE',
				pageId: 10,
				id: id,
			},
		},
	}

	const categoryExist = Object.hasOwnProperty.call(
		categoryToTitle,
		params.category,
	)

	if (!categoryExist) {
		throw new Response('Not Found', { status: 404 })
	} else {
		const subcategoryExist = Object.hasOwnProperty.call(
			categoryToTitle[params.category],
			params.subcategory,
		)

		if (!subcategoryExist) throw new Response('Not Found', { status: 404 })
		else {
			initPage(
				'Inscription ' +
					categoryToTitle[params.category][params.subcategory].title,
			)
			return categoryToTitle[params.category][params.subcategory]
		}
	}
}

export default registrationListLoader
