import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'page'

let listInProgress = []

export const GET_PAGE = 'GET_PAGE'
export const UPDATE_IMAGE_FROM_PAGE = 'UPDATE_IMAGE_FROM_PAGE'
export const UPDATE_TEXT_FROM_PAGE = 'UPDATE_TEXT_FROM_PAGE'

export const getPage = (id) => {
	const inProgessName = GET_PAGE + id
	if (!inProgressCheck(inProgessName, listInProgress)) {
		inProgressAdd(inProgessName, listInProgress)
		return async (dispatch) => {
			return axios
				.get(`${baseUrl}/${id}`, {
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_PAGE,
							payload: result.data,
						})
					} else {
						inProgressRemove(inProgessName, listInProgress)
					}
				})
				.catch((error) => {
					inProgressRemove(inProgessName, listInProgress)
					errorHandling(
						error,
						'récupérer le contenu de la page',
						GET_PAGE,
					)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateImageFromPage = (pageId, entityId, imageId) => {
	if (!inProgressCheck(UPDATE_IMAGE_FROM_PAGE, listInProgress)) {
		inProgressAdd(UPDATE_IMAGE_FROM_PAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.put(
					`${baseUrl}/${pageId}/${entityId}/image/${imageId}`,
					null,
					{
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: UPDATE_IMAGE_FROM_PAGE,
							payload: {
								pageId: pageId,
								entityId: entityId,
								imageId: imageId,
							},
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(
						error,
						'modifier une image dans la page',
						UPDATE_IMAGE_FROM_PAGE,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_IMAGE_FROM_PAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateTextFromPage = (pageId, textId, text) => {
	if (!inProgressCheck(UPDATE_IMAGE_FROM_PAGE, listInProgress)) {
		inProgressAdd(UPDATE_IMAGE_FROM_PAGE, listInProgress)
		return async (dispatch) => {
			return axios
				.put(`${baseUrl}/${pageId}/${textId}`, text, {
					headers: {
						'Content-Type': 'text/html; charset=utf-8',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: UPDATE_TEXT_FROM_PAGE,
							payload: {
								pageId: pageId,
								textId: textId,
								text: text,
							},
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(
						error,
						'modifier un texte dans la page',
						UPDATE_TEXT_FROM_PAGE,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_IMAGE_FROM_PAGE, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Unknown error'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
