import { useDispatch, useSelector } from 'react-redux'

import { deleteImage } from '../../../actions/image.action'

import { isRightAccess } from '../../../utils'
import { BASE_URL_IMAGE } from '../../../utils/env'

import PropTypes from 'prop-types'

import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ImageRow({ imgId, url, role, onClickImg, noDelete }) {
	const user = useSelector((state) => state.userReducer)
	const isConnected = user.isConnected
	const rightToDelete = isRightAccess(user, 6)
	const dispatch = useDispatch()

	function deleteImageHandling(e) {
		if (isConnected && rightToDelete) {
			// eslint-disable-next-line no-restricted-globals
			if (confirm("Êtes-vous sûr de vouloir supprimer l'image ?")) {
				dispatch(deleteImage(imgId))
			}
		}
	}

	return (
		<div className="SOUKIASSIAN-AdminImageTable-ImageRow">
			<div className="SOUKIASSIAN-AdminImageTable-ImageRow-image">
				<img
					className="SOUKIASSIAN-AdminImageTable-ImageRow-image-selected"
					src={`${BASE_URL_IMAGE}${url}`}
					alt={role}
					onClick={(e) => {
						onClickImg(e, imgId)
					}}
				/>
			</div>
			<div className="SOUKIASSIAN-AdminImageTable-ImageRow-title">
				{imgId} : {role}{' '}
				{!noDelete && rightToDelete && (
					<FontAwesomeIcon
						icon={faTrashCan}
						className="SOUKIASSIAN-AdminImageTable-ImageRow-title-trash"
						onClick={deleteImageHandling}
					/>
				)}
			</div>
		</div>
	)
}

ImageRow.propTypes = {
	imgId: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired,
	role: PropTypes.string,
	onClickImg: PropTypes.func,
	noDelete: PropTypes.bool,
}

ImageRow.defaultProps = {
	noDelete: false,
}

export default ImageRow
