import {
	GET_REGISTRATIONS,
	ADD_REGISTRATION,
	UPDATE_REGISTRATION,
	GET_REGISTRATIONS_DATA,
} from '../actions/registration.action'

const initialState = null

export default function registrationReducer(state = initialState, action) {
	switch (action.type) {
		case GET_REGISTRATIONS:
			return { ...state, list: [...action.payload] }
		case GET_REGISTRATIONS_DATA:
			return { ...state, categoryList: [...action.payload] }
		case ADD_REGISTRATION:
			return { ...state, list: [...state.list, action.payload] }
		case UPDATE_REGISTRATION:
			return {
				...state,
				list: updateById(state.list, action.payload),
			}
		default:
			return { ...state }
	}
}

function updateById(registrationList, updatedRegistration) {
	return registrationList.map((registration) => {
		if (updatedRegistration.id === registration.id) {
			return { ...updatedRegistration }
		} else {
			return registration
		}
	})
}
