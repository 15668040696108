import axios from 'axios'

import { inProgressCheck, inProgressAdd, inProgressRemove } from '../utils'
import { BASE_URL_API } from '../utils/env'

const baseUrl = BASE_URL_API + 'registration'

let listInProgress = []

export const GET_REGISTRATIONS = 'GET_REGISTRATIONS'
export const GET_REGISTRATIONS_DATA = 'GET_REGISTRATIONS_DATA'
export const ADD_REGISTRATION = 'ADD_REGISTRATION'
export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION'

export const getRegistrations = () => {
	if (!inProgressCheck(GET_REGISTRATIONS, listInProgress)) {
		inProgressAdd(GET_REGISTRATIONS, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '/getAll', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_REGISTRATIONS,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(
						error,
						'récupérer les inscriptions',
						GET_REGISTRATIONS,
					)
				})
				.finally(() => {
					inProgressRemove(GET_REGISTRATIONS, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const getRegistrationsData = () => {
	if (!inProgressCheck(GET_REGISTRATIONS_DATA, listInProgress)) {
		inProgressAdd(GET_REGISTRATIONS_DATA, listInProgress)
		return async (dispatch) => {
			return axios
				.get(baseUrl + '_data/getAll', { withCredentials: true })
				.then((result) => {
					if (result.status === 200) {
						dispatch({
							type: GET_REGISTRATIONS_DATA,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					errorHandling(
						error,
						"récupérer les catégories d'inscriptions",
						GET_REGISTRATIONS_DATA,
					)
				})
				.finally(() => {
					inProgressRemove(GET_REGISTRATIONS_DATA, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const addRegistration = (registration, setSuccess) => {
	if (!inProgressCheck(ADD_REGISTRATION, listInProgress)) {
		inProgressAdd(ADD_REGISTRATION, listInProgress)
		return async (dispatch) => {
			return axios
				.post(baseUrl + '/add', JSON.stringify(registration), {
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				})
				.then((result) => {
					if (result.status === 200) {
						setSuccess && setSuccess(true)
						dispatch({
							type: ADD_REGISTRATION,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data
					}
					errorHandling(
						error,
						'ajouter une inscription',
						ADD_REGISTRATION,
					)
				})
				.finally(() => {
					inProgressRemove(ADD_REGISTRATION, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

export const updateRegistration = (
	idRegistration,
	dataList,
	setIsEdit = false,
) => {
	if (!inProgressCheck(UPDATE_REGISTRATION, listInProgress)) {
		inProgressAdd(UPDATE_REGISTRATION, listInProgress)
		return async (dispatch) => {
			return axios
				.put(
					baseUrl + '/update/' + idRegistration,
					JSON.stringify(dataList),
					{
						headers: {
							'Content-Type': 'application/json',
						},
						withCredentials: true,
					},
				)
				.then((result) => {
					if (result.status === 200) {
						if (setIsEdit) {
							setIsEdit(false)
						}
						dispatch({
							type: UPDATE_REGISTRATION,
							payload: result.data,
						})
					}
				})
				.catch((error) => {
					console.log(error)
					if (error.response.status === 400) {
						return error.response.data.errors
					}
					errorHandling(
						error,
						"la mise à jour de l'inscription",
						UPDATE_REGISTRATION,
					)
				})
				.finally(() => {
					inProgressRemove(UPDATE_REGISTRATION, listInProgress)
				})
		}
	} else {
		return async () => {}
	}
}

const errorHandling = (error, title, actionType) => {
	let errorTitle = ''
	let errorMessage = ''

	if (error.response) {
		switch (error.response.status) {
			case 401:
				errorTitle = 'Erreur de connexion'
				errorMessage = error.response.data.message
				break

			case 404:
				errorTitle = 'Erreur pour ' + title
				errorMessage = "La requête n'existe pas"
				break

			case 500:
				errorTitle = 'Erreur serveur pour ' + title
				errorMessage = error.response.data.message
				break

			default:
				errorTitle = 'Erreur pour ' + title
				errorMessage = 'Erreur inconnue'
				break
		}
	} else if (error.request) {
		errorTitle = 'Erreur serveur pour ' + title
		errorMessage = 'Le serveur ne répond pas'
	} else {
		errorTitle = 'Erreur pour ' + title
		errorMessage = error.message
	}

	throw new Error(errorMessage, {
		cause: { type: actionType, title: errorTitle },
	})
}
