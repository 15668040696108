import PropTypes from 'prop-types'

import './index.scss'
import StringToNode from '../StringToNode'

function HarmoniousTitle({ title, subTitle, sticky, children, className }) {
	const stickyClass = sticky ? ' SOUKIASSIAN-HarmoniousTitle-sticky' : ''

	return (
		<div
			className={`SOUKIASSIAN-HarmoniousTitle${stickyClass} ${className}`}
		>
			<h2>
				<StringToNode string={title} />
				{subTitle && (
					<>
						<br />
						<span>{subTitle}</span>
					</>
				)}
			</h2>
			{children}
		</div>
	)
}

HarmoniousTitle.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	sticky: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
}

HarmoniousTitle.defaultProps = {
	sticky: false,
	className: '',
}

export default HarmoniousTitle
