import { BASE_URL_IMAGE } from './env'

const setTitlePage = (title) => (document.title = 'LCC - ' + title)
const toTop = function () {
	window.scrollTo(0, 0)
}

export const initPage = (title = false) => {
	toTop()
	if (title) setTitlePage(title)
	return null
}

export const getUrlFromImageList = (id, imageList) => {
	if (imageList != null && imageList[id] != null) {
		return BASE_URL_IMAGE + imageList[id]['url']
	}

	return ''
}

export const getDataFromImageList = (id, imageList) => {
	if (imageList != null && imageList[id] != null) {
		return imageList[id]
	}

	return null
}

export const filterImageListToArray = (imageList, filterFunction) => {
	const filteredImageList = []

	for (const id in imageList) {
		if (filterFunction(imageList[id])) {
			filteredImageList.push(imageList[id])
		}
	}

	return filteredImageList
}

export const getImageIdFromPageList = (pageList, idPage, idEntity) => {
	if (
		pageList != null &&
		pageList[idPage] != null &&
		pageList[idPage]['images'][idEntity] != null
	) {
		return pageList[idPage]['images'][idEntity]
	}

	return null
}

export const getAllImageIdFromPageList = (pageList) => {
	let listId = []

	if (pageList != null) {
		for (const key in pageList) {
			const page = pageList[key]

			for (const key in page['images']) {
				const imageId = parseInt(page['images'][key])
				listId.push(imageId)
			}
		}
	}

	return listId
}

export const getAllFolderFromPageList = (pageList) => {
	let listFolder = []

	if (pageList != null) {
		for (const key in pageList) {
			const page = pageList[key]

			listFolder.push(page)
		}
	}

	return listFolder
}

export const folderExist = (pageList, folderId) => {
	if (pageList != null) {
		if (Object.hasOwnProperty.call(pageList, folderId)) {
			return true
		}
	}

	return false
}

export const getAllImageIdFromFolder = (folder) => {
	let listId = []

	if (folder != null) {
		for (const key in folder['images']) {
			const imageId = parseInt(folder['images'][key])
			listId.push(imageId)
		}
	}

	return listId
}

export const isRightAccess = (user, rightAccessId) => {
	if (
		user?.rights.includes(rightAccessId.toString()) ||
		user?.rights.includes('1')
	) {
		return user?.isConnected
	}
}

export const inProgressCheck = (name, listName) => {
	return listName.includes(name)
}
export const inProgressAdd = (name, listName) => {
	if (!listName.includes(name)) {
		listName.push(name)
	}
}
export const inProgressRemove = (name, listName) => {
	const idName = listName.indexOf(name)

	if (idName !== -1) {
		listName.splice(idName, 1)
	}
}

export const categoryListFormatting = (categoryList = {}) => {
	const categoryListFormatted = {}

	for (const key in categoryList) {
		const category = categoryList[key]
		const categoryName = category.category
		const categoryTitle = category.title_category

		if (!categoryListFormatted[categoryName]) {
			categoryListFormatted[categoryName] = {
				list: [],
				title_category: [],
			}
		}
		if (
			!categoryListFormatted[categoryName].title_category.includes(
				categoryTitle,
			)
		) {
			categoryListFormatted[categoryName].title_category.push(
				categoryTitle,
			)
		}

		categoryListFormatted[categoryName].list.push(category)
	}

	return categoryListFormatted
}
