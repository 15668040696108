import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
	CONFIRM_ACCOUNT_CLIENT,
	confirmAccount,
} from '../../actions/client.action'

import PropTypes from 'prop-types'

import Collapse from '../../components/Collapse'
import Button from '../../components/Button'
import Success from '../../components/Success'
import Error from '../../components/Error'

function ClientConfirmAccount({ email, token }) {
	const [confirmSending, setConfirmSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [confirmSuccess, setConfirmSuccess] = useState(false)
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { ...errors, message: error.message }

		if (error.cause?.type === CONFIRM_ACCOUNT_CLIENT) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.tilte = 'Erreur du compte'
		}
		setErrors(errorContainer)
	}

	const confirmAccountHandling = function () {
		if (!confirmSending) {
			setConfirmSending(true)
			setErrors({})

			dispatch(confirmAccount(email, token, setConfirmSuccess))
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					}
				})
				.catch(errorHandling)
				.finally(() => {
					setConfirmSending(false)
				})
		}
	}

	useEffect(() => {
		if (email && token) {
			confirmAccountHandling()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Collapse title="Confirmation du compte" open>
			{(confirmSuccess && (
				<>
					<Success
						className="col-11"
						success={
							<>
								La confirmation a été effectuée.
								<br />
								<br />
								Maintenant, merci de bien vouloir créer votre
								mot de passe, en appuyant sur le bouton ci
								dessous.
							</>
						}
					/>
					<Button
						className="center"
						isDisabled={confirmSending}
						link={`/client-account/new-password/${confirmSuccess.email}/${confirmSuccess.token}`}
					>
						Initialiser mon mot de passe
					</Button>
				</>
			)) ||
				((errors.message || errors.title) && (
					<Error
						className="col-11"
						error={`${errors.title} : ${errors.message}`}
						deleteError={() => {
							setErrors({})
						}}
					/>
				))}
		</Collapse>
	)
}

ClientConfirmAccount.propTypes = {
	email: PropTypes.string.isRequired,
	token: PropTypes.string.isRequired,
}

export default ClientConfirmAccount
