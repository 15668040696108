import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { LOGIN, login } from '../../actions/user.action'

import Input from '../Input'
import Button from '../Button'
import Error from '../Error'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-regular-svg-icons'

import './index.scss'

function Login() {
	const [sending, setSending] = useState(false)
	const [errorToLogin, setErrorToLogin] = useState(null)
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { message: error.message }

		if (error.cause?.type === LOGIN) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.tilte = 'Error to connect'
		}
		setErrorToLogin(errorContainer)
	}

	const signIn = (evt) => {
		evt.preventDefault()
		if (!sending) {
			setSending(true)

			const formData = new FormData(evt.target)

			const user = formData.get('user')
			const password = formData.get('password')

			dispatch(login(user, password))
				.catch(errorHandling)
				.finally(() => {
					setSending(false)
				})
		}
	}

	return (
		<div className="SOUKIASSIAN-Login">
			<span className="SOUKIASSIAN-Login-title">
				<FontAwesomeIcon
					icon={faCircleUser}
					className="SOUKIASSIAN-Login-title-icon"
				/>
				CONNEXION
			</span>
			<form onSubmit={signIn}>
				{errorToLogin && (
					<Error
						error={`${errorToLogin.title} : ${errorToLogin.message}`}
						deleteError={() => {
							setErrorToLogin(null)
						}}
					/>
				)}
				<label>Utilisateur :</label>
				<Input name="user" title="utilisateur" />
				<label>Mot de passe :</label>
				<Input type="password" name="password" title="mot de passe" />

				<Button isDisabled={sending}>Se connecter</Button>
			</form>
		</div>
	)
}

export default Login
