import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { sendMessage } from '../../actions/contact.action'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Success from '../../components/Success'

function RegistrationForm() {
	const [doReset, setDoReset] = useState(false)
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState(false)
	const dispatch = useDispatch()
	const pageId = 29

	const sendMessageHandling = function (e) {
		e.preventDefault()
		// const form = new FormData(e.target)

		// dispatch(sendMessage(form, setDoReset)).then((errors) => {
		// 	if (errors) {
		// 		setErrors(errors)
		// 	} else {
		// 		setSuccess(true)
		// 	}
		// })
	}

	return (
		<form
			className="SOUKIASSIAN-Registration-form"
			action="/registration"
			onSubmit={sendMessageHandling}
		>
			<div className="SOUKIASSIAN-Registration-form-row col-11">
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					name="fullName"
					message="NOM et Prénom *"
					title="Votre nom et prénom"
					maxLength="255"
					errorDirect={errors.fullName ? errors.fullName : null}
					reset={[doReset]}
					required
				/>
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					type="tel"
					name="business"
					message="Entreprise"
					title="Votre entreprise"
					maxLength="255"
					errorDirect={errors.business ? errors.business : null}
					reset={[doReset]}
					colorPro
				/>
			</div>
			<div className="SOUKIASSIAN-Registration-form-row col-11">
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					type="email"
					name="email"
					message="Adresse e-mail *"
					title="Votre e-mail"
					maxLength="255"
					errorDirect={errors.email ? errors.email : null}
					reset={[doReset]}
					required
				/>
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					type="tel"
					name="professionalPhone"
					message="Téléphone professionnel"
					title="Votre téléphone professionnel"
					maxLength="255"
					errorDirect={
						errors.professionalPhone
							? errors.professionalPhone
							: null
					}
					reset={[doReset]}
					colorPro
				/>
			</div>
			<div className="SOUKIASSIAN-Registration-form-row col-11">
				<Input
					className="col-5 col-xs-12 col-xxs-12"
					type="tel"
					name="personalPhone"
					message="Téléphone personnel"
					title="Votre téléphone personnel"
					maxLength="255"
					errorDirect={
						errors.personalPhone ? errors.personalPhone : null
					}
					reset={[doReset, setDoReset]}
				/>
			</div>
			<span className="SOUKIASSIAN-Registration-form-condition">
				Prix de la prestation : 100 euros
			</span>
			<br />
			<span className="SOUKIASSIAN-Registration-form-condition">
				* Merci de remplir ces champs pour vous inscrire
			</span>
			{success && (
				<Success
					className="col-11"
					success="L'inscription a été confirmée"
				/>
			)}
			<Button className="col-xxs-11 col-xs-11 col-4">
				VALIDER L'INSCRIPTION
			</Button>
		</form>
	)
}

export default RegistrationForm
