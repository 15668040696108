import Home from '../../pages/Home'
import Contact from '../../pages/Contact'
import Registration from '../../pages/Registration'
import Error404 from '../../pages/Error/404'
import ClientAccount from '../../pages/ClientAccount'
import LegalNotice from '../../pages/LegalNotice'

import { initPage } from '../../utils'

import registrationListLoader from './registrationListLoader'

// Path : Header and Footer
export const pathUtilsPages = [
	{
		path: '/contact',
		element: <Contact />,
		loader: () => initPage('Contact'),
	},
	{
		path: '/legal-notice',
		element: <LegalNotice />,
		loader: () => initPage('Mentions Légales'),
	},
	{
		path: '/blog',
		element: <Home />,
		loader: () => initPage('Blog'),
	},
	{
		path: '/podcast',
		element: <Home />,
		loader: () => initPage('Podcast'),
	},
	{
		path: '/registration',
		element: <Registration />,
		errorElement: <Error404 />,
		loader: () => initPage('Inscription'),
		children: [
			{
				path: ':category/:subcategory',
				element: <Registration isListRegistration />,
				loader: registrationListLoader,
			},
			{
				path: ':category/:subcategory/:id',
				element: <Registration isIdRegistration />,
				loader: registrationListLoader,
			},
		],
	},
	{
		path: '/client-account',
		element: <ClientAccount />,
		loader: () => initPage('Compte client'),
		children: [
			{
				path: 'confirm-account/:email/:token',
				element: <ClientAccount isConfirmAccount />,
				loader: ({ params }) => {
					return params
				},
			},
			{
				path: 'new-password/:email/:token',
				element: <ClientAccount isNewPassword />,
				loader: ({ params }) => {
					return params
				},
			},
		],
	},
]
// Path END : Header
