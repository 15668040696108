import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import HarmoniousTitle from '../../components/HarmoniousTitle'
import Button from '../../components/Button'
import ImageTextContainer from '../../components/ImageTextContainer'
import ImageText from '../../components/ImageTextContainer/ImageText'
import RegistrationForm from './Form'
import LoginClient from '../../components/LoginClient'

import './index.scss'

function RegistrationList({ data }) {
	const client = useSelector((state) => state.clientReducer)
	const isConnected = client.isConnected

	const pageId = 29

	return (
		<div className="SOUKIASSIAN-RegistrationList">
			<div className="col-max-blocked center margined-y">
				<Button link="/registration">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX INSCRIPTIONS'}
				</Button>
			</div>
			<HarmoniousTitle
				className="col-max-blocked"
				title={`S'INSCRIRE ${data?.HarmoniousTitle}`}
			>
				{data?.id === 0 ? ( //Registration List if no id
					<ImageTextContainer>
						<ImageText
							pageId={data?.pageIdCategory}
							text={
								<>
									<span>
										<b>Programme</b> : Bien vivre sa vie au
										quotidien.
									</span>
									<span>
										<b>Date</b> : Le jeudi 18 juillet 2024
										<br />
										<b>Horaire</b> : 19h - 21h
									</span>
									<span>
										<Button link="1">S'INSCRIRE</Button>
									</span>
								</>
							}
						/>
					</ImageTextContainer>
				) : isConnected ? ( //Registration by id if id exist and if client is connected
					<>
						<ImageTextContainer className="col-11">
							<ImageText
								pageId={data?.pageIdCategory}
								text={
									<>
										<span>
											<b>Programme</b> : Bien vivre sa vie
											au quotidien.
										</span>
										<span>
											<b>Date</b> : Le jeudi 18 juillet
											2024
											<br />
											<b>Horaire</b> : 19h - 21h
										</span>
									</>
								}
								noMinHeight
							/>
						</ImageTextContainer>
						<RegistrationForm />
					</>
				) : (
					//OR if id exist AND if client is not connected
					<LoginClient />
				)}
			</HarmoniousTitle>
		</div>
	)
}

RegistrationList.propTypes = {
	data: PropTypes.object,
}

RegistrationList.defaultProps = {
	data: { id: 0, HarmoniousTitle: '...', pageIdCategory: null },
}

export default RegistrationList
