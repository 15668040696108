import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function ConsciousnessInitiation() {
	const pageId = 19

	return (
		<div className="SOUKIASSIAN-ConsciousnessInitiation">
			<Header
				type="finalPage"
				title="STAGE INITIATIQUE CONSCIENCE"
				subTitle="“Rencontre, échange et pratique de la conscience.”"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					title="INITIATION À LA CONSCIENCE"
					text={
						<>
							<span>
								Découvrez une initiation au cœur de la
								conscience !
							</span>
							<span>
								Rejoignez-nous lors d'un partage passionnant où
								nous explorerons ensemble les mystères de la
								conscience.
							</span>
							<span>
								À travers des échanges enrichissants et un
								atelier interactif, plongez dans une expérience
								unique.
							</span>
							<span>
								<br />
								<b>Nous rejoindre</b>
								<br />
								<br />
								pour une exploration intuitive des états
								inconscients avec les possibilités infinies de
								la conscience.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title={
						<>
							(1 demi-journée)
							<br />
							Animé par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								<b>Conférence (1 heure)</b>
								<br />
								“Explorez votre conscience.
								<br />
								Osez vivre et rayonnez en conscience.”
							</span>
							<span>
								<b>Échanges (1 heure)</b>
								<br />
								L'animateur et les participants partagent leur
								ressenti sur le sujet de la conférence.
							</span>
							<span>
								<b>Atelier (2 heures)</b>
								<br />
								Les participants expérimentent la conscience en
								communiquant sur les thèmes de leur choix.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Initiatique conscience”
						</>
					}
					textCenter
					text={
						<span>
							<Button link="/contact">S'INSCRIRE</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Camille Lavy"
				commentary={
					<>
						Ce stage d'initiation m'a offert de la clarté avec une
						joie intense. j'ai été guidé avec mon moi intérieur pour
						faire évoluer ma vie.
						<br />
						<br />
						Porté par l'échange dynamique du groupe j'ai aussi pu
						cultiver une conscience élargie, savourant chaque
						instant avec reconnaissance.
					</>
				}
			/>

			<BannerImageText
				pageId={pageId}
				entityId={5}
				text={
					<>
						<span>STAGES</span>
						<br />
						Lyon et autres villes de France
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default ConsciousnessInitiation
