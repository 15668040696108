import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function Meditation() {
	const pageId = 21

	return (
		<div className="SOUKIASSIAN-Meditation">
			<Header
				type="finalPage"
				title="STAGE MÉDITATION"
				subTitle="“Méditez en conscience au quotidien, réalisez plus facilement vos actes de vie.”"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					title="MÉDITATION CONSCIENTE"
					text={
						<>
							<span>Participez à la méditation L.C.C. © !</span>
							<span>
								Initions-nous à l'art transformateur de la
								méditation consciente communicative.
							</span>
							<span>
								Rencontrez votre conscience dans de courts
								instants, soyez dans la pleine présence.
							</span>
							<span>
								<br />
								<b>Nous rejoindre</b>
								<br />
								<br />
								dans une session d'écoute consciente intérieure
								avec un pouvoir de transformation sans
								précédent.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title={
						<>
							(1 journée)
							<br />
							Guidé par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								<b>La méditation consciente L.C.C. ©</b>
								<br />
								“Rencontrez le calme intérieur.
								<br />
								Osez méditer en pleine conscience.”
							</span>
							<span>
								<b>Communication avec l'inconscient</b>
								<br />
								Les participants traversent en conscience leurs
								états néfastes en évolution positive.
							</span>
							<span>
								<b>Ouvrez les portes de votre transformation</b>
								<br />
								Apprenez à générer instinctivement des pensées
								positives et agissez sous le guide d'une
								nouvelle conscience évolutive.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Médiation consciente”
						</>
					}
					textCenter
					text={
						<span>
							<Button>S'INSCRIRE</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Capucine Jeannerod"
				commentary={
					<>
						La méditation consciente LCC m'a permis de me comprendre
						profondément, apportant des apaisements inattendus
						journaliers.
						<br />
						<br />
						Grâce à cette pratique quotidienne, j'agis régulièrement
						en paix avec moi-même.
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={5}></BannerImageText>

			<Footer />
		</div>
	)
}

export default Meditation
