import reactStringReplace from 'react-string-replace'

import PropTypes from 'prop-types'

function StringToNode({ string }) {
	var node = reactStringReplace(string, '<br>', (match, i) => <br key={i} />)

	return node
}

StringToNode.propTypes = {
	string: PropTypes.string.isRequired,
}

export default StringToNode
