import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPage } from '../../actions/page.action'
import { getImages } from '../../actions/image.action'

import { getUrlFromImageList, getImageIdFromPageList } from '../../utils'

import PropTypes from 'prop-types'

import ImageInfo from '../ImageInfo'

function ImageText({
	pageId,
	entityId,
	text,
	textCenter,
	title,
	left,
	right,
	flippedImage,
	noMinHeight,
}) {
	const pageList = useSelector((state) => state.pageReducer.list)
	const imageList = useSelector((state) => state.imageReducer.list)
	const dispatch = useDispatch()

	const imgBackgroundId = getImageIdFromPageList(pageList, pageId, entityId)

	useEffect(() => {
		if (pageId != null) {
			dispatch(getPage(pageId))
		}
		dispatch(getImages())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const classTextCenter = textCenter
		? ' SOUKIASSIAN-ImageTextContainer-child-textAlignCenter'
		: ''
	const classNoImage = imgBackgroundId
		? ''
		: ' SOUKIASSIAN-ImageTextContainer-childNoImage'
	const isFlippedClass = flippedImage
		? ' SOUKIASSIAN-ImageTextContainer-child-imgBackgroundFlipped'
		: ''
	const noMinHeightClass = noMinHeight
		? ' SOUKIASSIAN-ImageTextContainer-childNoMinHeight'
		: ''

	return (
		<article
			className={`SOUKIASSIAN-ImageTextContainer-child${classNoImage}${noMinHeightClass}`}
		>
			{right && !left && (text || title) && (
				<div
					className={`SOUKIASSIAN-ImageTextContainer-child-text${classTextCenter}`}
				>
					{title && <h2>{title}</h2>}
					<p>{text}</p>
				</div>
			)}

			{imgBackgroundId && (
				<div
					className={`SOUKIASSIAN-ImageTextContainer-child-imgBackground${isFlippedClass}`}
					style={{
						backgroundImage: `url(${getUrlFromImageList(imgBackgroundId, imageList)})`,
					}}
				>
					<ImageInfo
						imgData="GET"
						pageId={pageId}
						entityId={entityId}
					/>
				</div>
			)}

			{left && (text || title) && (
				<div className="SOUKIASSIAN-ImageTextContainer-child-text">
					{title && <h2>{title}</h2>}
					<p>{text}</p>
				</div>
			)}
		</article>
	)
}

ImageText.propTypes = {
	pageId: PropTypes.number,
	entityId: PropTypes.number,
	text: PropTypes.any,
	textCenter: PropTypes.bool,
	title: PropTypes.any,
	left: PropTypes.bool,
	right: PropTypes.bool,
	flippedImage: PropTypes.bool,
}

ImageText.defaultProps = {
	textCenter: false,
	left: false,
	right: true,
	flippedImage: false,
}

export default ImageText
