import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import { isRightAccess } from '../../../utils'

import {
	ADD_REGISTRATION,
	addRegistration,
} from '../../../actions/registration.action'

import HarmoniousTitle from '../../../components/HarmoniousTitle'
import Button from '../../../components/Button'
import ImageTextContainer from '../../../components/ImageTextContainer'
import ImageText from '../../../components/ImageTextContainer/ImageText'
import Success from '../../../components/Success'
import Error from '../../../components/Error'

import '../../RegistrationList/index.scss'

function RegistrationList({ data }) {
	const user = useSelector((state) => state.userReducer)
	const [addRegistrationSending, setAddRegistrationSending] = useState(false)
	const [errors, setErrors] = useState({})
	const [addRegistrationSuccess, setAddRegistrationSuccess] = useState(false)
	const dispatch = useDispatch()

	const errorHandling = (error) => {
		let errorContainer = { ...errors, message: error.message }

		if (error.cause?.type === ADD_REGISTRATION) {
			errorContainer.title = error.cause.title
		} else {
			errorContainer.tilte = 'Erreur des inscriptions'
		}
		setErrors(errorContainer)
	}

	const addRegistrationHandling = function () {
		if (!addRegistrationSending) {
			setAddRegistrationSending(true)
			setErrors({})

			const registration = {}

			dispatch(addRegistration(registration, setAddRegistrationSuccess))
				.then((errors) => {
					if (errors) {
						setErrors(errors)
					}
				})
				.catch(errorHandling)
				.finally(() => {
					setAddRegistrationSending(false)
				})
		}
	}

	return (
		<div className="SOUKIASSIAN-RegistrationList">
			<div className="col-max-blocked center margined-y">
				<Button link="/registration">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX INSCRIPTIONS'}
				</Button>
			</div>
			<HarmoniousTitle
				className="col-max-blocked"
				title={`S'INSCRIRE ${data?.HarmoniousTitle}`}
			>
				<ImageTextContainer>
					<ImageText
						pageId={data?.pageIdCategory}
						text={
							<>
								<span>
									<b>Programme</b> : Bien vivre sa vie au
									quotidien.
								</span>
								<span>
									<b>Date</b> : Le jeudi 18 juillet 2024
									<br />
									<b>Horaire</b> : 19h - 21h
								</span>
								{isRightAccess(user, 8) && (
									<span>
										<Button link="1">MODIFIER</Button>
									</span>
								)}
							</>
						}
						noMinHeight
					/>
					<ImageText
						text={
							<>
								<span>
									<b>Programme</b>
								</span>
								<span>
									{addRegistrationSuccess ? (
										<Success
											className="col-11"
											success={
												<>L'inscription a été créée.</>
											}
										/>
									) : (
										(errors.message || errors.title) && (
											<Error
												className="col-11"
												error={`${errors.title} : ${errors.message}`}
												deleteError={() => {
													setErrors({})
												}}
											/>
										)
									)}
									{isRightAccess(user, 8) && (
										<Button
											onClick={addRegistrationHandling}
											isDisabled={addRegistrationSending}
										>
											AJOUTER
										</Button>
									)}
								</span>
							</>
						}
						noMinHeight
					/>
				</ImageTextContainer>
			</HarmoniousTitle>
		</div>
	)
}

RegistrationList.propTypes = {
	data: PropTypes.object,
}

RegistrationList.defaultProps = {
	data: { id: 0, HarmoniousTitle: '...', pageIdCategory: null },
}

export default RegistrationList
