import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import Button from '../../../../components/Button'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'

function Webinars() {
	const pageId = 15

	return (
		<div className="SOUKIASSIAN-Webinars">
			<Header
				type="finalPage"
				title="WEBINAIRE"
				subTitle={
					<>
						Animé par Christophe Soukiassian
						<br />
						<br />
						“Découvrez votre potentiel conscience”
					</>
				}
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/events">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX ÉVÉNEMENTS'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={3}
					text={
						<>
							<span>
								<b>Programme</b> : Bien vivre sa vie au
								quotidien.
							</span>
							<span>
								<b>Date</b> : Le jeudi 18 juillet 2024
								<br />
								<b>Horaire</b> : 19h - 21h
							</span>
							<span>
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
				/>
				<ImageText pageId={pageId} entityId={4} left />
				<ImageText pageId={pageId} entityId={5} />
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={1}
				name="Mia Sem"
				commentary="Un webinaire captivant qui m'éclaire dans mon épanouissement personnel, avec des outils pratiques pour embellir ma vie."
			/>

			<BannerImageText
				pageId={pageId}
				entityId={2}
				text={
					<>
						<span>WEBINAIRE</span>
						<br />
						Partout dans le monde
					</>
				}
			></BannerImageText>

			<Footer />
		</div>
	)
}

export default Webinars
