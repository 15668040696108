import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

function TrainingLifeCoaching() {
	const pageId = 9

	return (
		<div className="SOUKIASSIAN-TrainingLifeCoaching">
			<Header
				type="finalPage"
				title="Formation en Communication Consciente"
				subTitle="COACHER SA VIE"
			/>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					text={
						<>
							<span>
								L'école L.C.C. © propose une formation
								innovante dédiée à la{' '}
								<b>
									maîtrise de l'art de la communication
									consciente.
								</b>
							</span>
							<span>
								Vous apprenez à{' '}
								<span className="underline">
									développer votre potentiel véritable
								</span>{' '}
								pour{' '}
								<span className="underline">
									améliorer votre vie et faciliter celle des
									autres.
								</span>
							</span>
							<span>
								Ouverte à tous, cette formation vous donne{' '}
								<span className="underline">
									les solutions pour comprendre et agir
									naturellement
								</span>{' '}
								en conscience.
							</span>
							<span>
								Transformez votre quotidien en une aventure
								épanouissante.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					text={
						<>
							<span>
								La formation comprend <b>trois concepts clés</b>{' '}
								de communication avec les états inconscients.
							</span>
							<span>
								<b>Le premier concept E.E.N.B.</b> © apprend l'
								<b>É</b>coute et l'<b>E</b>xpression <b>N</b>on{' '}
								<b>B</b>lessantes.
							</span>
							<span>
								<b>Le concept I.T.</b> ©, traite les <b>I</b>
								nterprétations et
								<b>T</b>ransformations.
							</span>
							<span>
								<b>Enfin R.C.G.</b> ©, facilite le <b>R</b>
								essenti, la <b>C</b>ompréhension et la <b>G</b>
								estion positive des évolutions.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					text={
						<>
							<span>
								Les modules de notre formation s'articulent
								harmonieusement autour d'une pédagogie intuitive
								et fluide, écrite, verbale et non verbale.
							</span>
							<span>
								Nos cours, 30% théorique et 70% pratique,
								offrent une expérience d'apprentissage,
								enrichissante et complète.
							</span>
							<span className="underline">
								Les participants se révèlent à eux-mêmes et
								trouvent leur équilibre dans tous les domaines
								de leur vie.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={4}
					flippedImage
					text={
						<>
							<span>
								<b>En groupe</b>
								<br />
								18 jours non-consécutifs.
								<br />
								185 € la journée par personne
							</span>
							<span>
								<b>En individuel</b>
								<br />
								18 demi-journées non-consécutives.
								<br />
								235 € la demi-journée par personne
							</span>
							<span>
								<hr />
								<Button
									onClick={(e) => {
										e.preventDefault()
										alert(
											"Le programme est en cours d'actualisation juillet-août 2024",
										)
									}}
								>
									Télécharger le programme
								</Button>
								<br />
								<Button link="/contact">S'INSCRIRE</Button>
							</span>
						</>
					}
					left
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={5}
				name="Renaud"
				commentary={
					<>
						J'ai vraiment éprouvé beaucoup de plaisirs à entrer en
						formation pour y découvrir une méthode qui déchire dans
						le sens où j'ai pu me retrouver avec moi et les autres
						en sachant ce que je voulais accomplir dans ma vie,
						merci à tout le groupe, quelle belle formation
					</>
				}
			/>

			<BannerImageText pageId={pageId} entityId={6}></BannerImageText>

			<Footer />
		</div>
	)
}

export default TrainingLifeCoaching
